<template>
  <div class="bbs">
    <router-view />
    <!-- <van-tabbar v-model="active">
      <van-tabbar-item icon="home-o">首页</van-tabbar-item>
      <van-tabbar-item icon="friends-o">我的</van-tabbar-item>
      <van-tabbar-item icon="setting-o">发帖</van-tabbar-item>
    </van-tabbar> -->
    <div style="height: 50px"></div>
    <van-tabbar v-model="active" @change="handleChange">
      <van-tabbar-item replace icon="wap-home-o">首页</van-tabbar-item>
      <van-tabbar-item replace icon="star-o">收藏</van-tabbar-item>
      <van-tabbar-item replace icon="friends">我的</van-tabbar-item>
    </van-tabbar>
  </div>
</template>
<script>
import { queryStudentInfoById } from "@/api/bbs";
let Load;
export default {
  name: "index",
  components: {},
  computed: {},
  watch: {},
  data () {
    return {
      active: 0,
      user: {},
    };
  },
  created () {
    var that = this;
    that.init();
    console.log(that.active, '')
    window["callByAndroidParam"] = function (jsonStr) {
      if (jsonStr != "") {
        that.user.Id = jsonStr.data;
        that.getQueryUserInfo();
        that.handleChange();
      } else {
        this.$message.error("登录失败");
      }
    };
  },
  mounted () {
    window.userIDResultByIos = this.userIDResultByIos;
  },
  methods: {
    userIDResultByIos (jsonStr) {
      if (jsonStr != "") {
        this.user.Id = jsonStr.data;
        this.getQueryUserInfo();
      } else {
        this.$message.error("登录失败");
      }
    },
    // 用户详情
    async getQueryUserInfo () {
      const res = await queryStudentInfoById(this.user.Id);
      this.user = res.response;
      localStorage.setItem("userInfo", JSON.stringify(this.user));
    },
    handleChange () {
      if (this.active == 0) {
        this.$router.push({
          path: "/H5/bbs/Index",
          query: {},
        });
      } else if (this.active == 1) {
        this.gotoLogin("/H5/bbs/myCollection");
      } else if (this.active == 2) {
        this.gotoLogin("/H5/bbs/myTopic");
      }
    },
    //  判断是否登录
    gotoLogin (url) {
      if (localStorage.getItem("userInfo") != null) {
        this.user = JSON.parse(localStorage.getItem("userInfo"));
        this.$router.push({
          path: url,
          query: {},
        });
      } else {
        this.user.Id = 0;
        this.callApp();
      }
    },
    callApp () {
      let u = navigator.userAgent;
      let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
      if (window.android != null && typeof window.android != "undefined") {
        //可以传递自定义数据，比如json
        window.android.callAndroid(JSON.stringify({ action: "getUserID" }));
      } else if (isiOS) {
        window.webkit.messageHandlers.callIosGetUserID.postMessage(JSON.stringify({ action: "callIosGetUserID" }));
      }
    },
    init () {
      let route = this.$route.path.split("/")[3];
      if (route === "Index") {
        this.active = 0;
      } else if (route === "myCollection") {
        this.active = 1;
      } else if (route === "myTopic") {
        this.active = 2;
      } else {
        this.active = 0;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.bbs {
  // height: calc(100vh - 50px);
  // overflow-y: scroll;
}
</style>
